<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('accounting.menu') }}</h1>
                </div>
            </div>
            <v-row>
                <v-col>
                    <v-card class="w-100 d-flex flex-column" style="height: 100%">
                        <v-card-title>
                            {{ $t('warehouse.process') }}
                        </v-card-title>
                        <v-card-text class="process__wrap flex-grow-1">
                            <table class="process">
                                <tr>
                                    <td>
                                        <RouterLink :to="{name: 'buy.buyer_order'}" class="process__step">
                                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 7.17395C2 6.62167 2.44772 6.17395 3 6.17395H49C49.5523 6.17395 50 6.62167 50 7.17395V49C50 49.5523 49.5523 50 49 50H3C2.44771 50 2 49.5523 2 49V7.17395Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M12.4349 2V12.4348" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M39.5652 2V12.4348" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 16.6087H50" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26 39V43" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26 22V25" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M19 39H29.5C31.433 39 33 37.433 33 35.5C33 33.567 31.433 32 29.5 32H22.5C20.567 32 19 30.433 19 28.5C19 26.567 20.567 25 22.5 25H33" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('vouchers_log.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'buy.invoice_outgoing'}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 42C18.6569 42 20 40.6569 20 39C20 37.3431 18.6569 36 17 36C15.3431 36 14 37.3431 14 39C14 40.6569 15.3431 42 17 42Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 2H7.416C8.34381 2.0004 9.14953 2.63884 9.362 3.542L17 36H25" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M44.968 23.094L47.122 16.632C47.325 16.0224 47.2228 15.3523 46.8472 14.831C46.4717 14.3097 45.8685 14.0005 45.226 14H11.824" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M15.588 30H24.95" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M28 38C28 43.5228 32.4772 48 38 48C43.5228 48 48 43.5228 48 38C48 32.4772 43.5228 28 38 28C32.4772 28 28 32.4772 28 38Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M42 38L34 38" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M38 34L42 38L38 42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('processing_bank_statements.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><div class="arrow up-left"></div></td>
                                    <td></td>
                                    <td><div class="arrow up-right"></div></td>
                                    <td></td>
                                    <td><div class="arrow down-right"></div></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <RouterLink :to="{name: 'account_payment', query: {filter: {payment_direction_id: {eq: '1'}}}}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M28.974 24.69C30.9859 20.4716 35.6513 18.2094 40.2093 19.2419C44.7674 20.2745 48.0024 24.3264 48 29V36H43L42 48H34L33 36H30.036" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M16.9999 30H12.9339C11.6486 30.0009 10.5444 30.9128 10.3005 32.1747C10.0566 33.4366 10.7415 34.6942 11.9339 35.174L16.0619 36.826C17.2542 37.3058 17.9391 38.5634 17.6952 39.8253C17.4513 41.0872 16.3471 41.9991 15.0619 42H10.9999" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14 44V42.002" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 48C20.6274 48 26 42.6274 26 36C26 29.3726 20.6274 24 14 24C7.37258 24 2 29.3726 2 36C2 42.6274 7.37258 48 14 48Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14 30.002V28" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17 11L9 11" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M16 7L20 11L16 15" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31.9678 5.44601C34.1581 7.71768 37.1782 9.00067 40.3338 9.00001C41.9154 9.00038 43.4806 8.6785 44.9338 8.05401" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M38 16C41.866 16 45 12.866 45 9C45 5.13401 41.866 2 38 2C34.134 2 31 5.13401 31 9C31 12.866 34.134 16 38 16Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('incoming_payment.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td>
                                        <div class="arrow left"></div>
                                    </td>
                                    <td>
                                        <RouterLink :to="{name: 'transaction_log'}" class="process__step">
                                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M45 37L39.7525 43.4974C39.5164 43.7889 39.1558 43.9711 38.764 43.9969C38.3721 44.0226 37.9875 43.8893 37.7095 43.6316L35 41.1158" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M50 28V7.17392C50 6.62163 49.5523 6.17392 49 6.17392H3C2.44772 6.17392 2 6.62164 2 7.17392V49C2 49.5523 2.44772 50 3 50L29 50" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M12.4349 2V12.4348" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M39.5652 2V12.4348" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 16.6087H50" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.1738 35.4348V37.5217" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M17.1738 25V27.087" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M13 35.4327H19.2609C20.4135 35.4327 21.3478 34.4983 21.3478 33.3457C21.3478 32.1931 20.4135 31.2588 19.2609 31.2588H15.087C13.9344 31.2588 13 30.3244 13 29.1718C13 28.0192 13.9344 27.0849 15.087 27.0849H21.3478" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 40C30 45.5228 34.4772 50 40 50C45.5228 50 50 45.5228 50 40C50 34.4772 45.5228 30 40 30C34.4772 30 30 34.4772 30 40Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('transactions_log.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td>
                                        <div class="arrow"></div>
                                    </td>
                                    <td>
                                        <RouterLink :to="{name: 'warehouse.delivering'}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M30 20V12H8V37.998L18 38" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path
                                                    d="M36 18V7.99999C36 6.89542 35.1046 5.99999 34 5.99999H25.104C24.0439 3.57629 21.6494 2.01013 19.004 2.01013C16.3586 2.01013 13.9641 3.57629 12.904 5.99999H4C2.89543 5.99999 2 6.89542 2 7.99999V42C2 43.1046 2.89543 44 4 44H20"
                                                    stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14 18H24" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14 24H24" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14 30H20" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36 48C42.6274 48 48 42.6274 48 36C48 29.3726 42.6274 24 36 24C29.3726 24 24 29.3726 24 36C24 42.6274 29.3726 48 36 48Z" stroke="#898989"
                                                      stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M41.348 32.51L35.538 40.258C35.2766 40.6056 34.8773 40.8229 34.4435 40.8536C34.0096 40.8843 33.5837 40.7254 33.276 40.418L30.276 37.418" stroke="#898989" stroke-width="2.5"
                                                      stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{ $t('internal_reconciliations.menu') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td>
                                        <div class="arrow"></div>
                                    </td>
                                    <td>
                                        <RouterLink :to="{name: 'sale.accounting.transfers_outgoing'}" class="process__step">
                                            <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.036 36.124C36.6571 35.4 36.999 34.4779 37 33.524V12H11V42H29.16C30.3285 41.9995 31.4384 41.488 32.198 40.6L36.036 36.124Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M41 5.99999H30.104C29.0439 3.57629 26.6494 2.01013 24.004 2.01013C21.3586 2.01013 18.9641 3.57629 17.904 5.99999H7C5.89543 5.99999 5 6.89542 5 7.99999V46C5 47.1046 5.89543 48 7 48H41C42.1046 48 43 47.1046 43 46V7.99999C43 6.89542 42.1046 5.99999 41 5.99999Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 18H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 26H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26 34H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{$t('closing_of_period.self')}}</span>
                                        </RouterLink>
                                    </td>
                                    <td><div class="arrow"></div></td>
                                    <td>
                                        <RouterLink :to="{name: 'accounting.dashboard'}" class="process__step">
                                            <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 44.008H39" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M24 40.008V44.008" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M2 32.008H48" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 28.008C23.5228 28.008 28 23.5308 28 18.008C28 12.4851 23.5228 8.008 18 8.008C12.4772 8.008 8 12.4851 8 18.008C8 23.5308 12.4772 28.008 18 28.008Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M23.654 9.76001L18 18.008L24.41 25.68" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M18 18.008H8" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M34 10.008H42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M34 16.008H42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M34 22.008H42" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5.508C2 4.67957 2.67157 4.008 3.5 4.008H46.5C47.3284 4.008 48 4.67957 48 5.508V38.508C48 39.3364 47.3284 40.008 46.5 40.008H3.5C2.67157 40.008 2 39.3364 2 38.508V5.508Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{$t('accounting.accounting_report')}}</span>
                                        </RouterLink>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><div class="arrow down-left"></div></td>
                                    <td></td>
                                    <td><div class="arrow down-right"></div></td>
                                    <td></td>
                                    <td><div class="arrow up-right"></div></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <RouterLink :to="{name: 'account_payment', query: {filter: {payment_direction_id: {eq: '2'}}}}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M39 30H34.934C33.6487 30.0009 32.5445 30.9128 32.3006 32.1747C32.0567 33.4366 32.7416 34.6942 33.934 35.174L38.062 36.826C39.2543 37.3058 39.9392 38.5634 39.6953 39.8253C39.4514 41.0872 38.3472 41.9991 37.062 42H33" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M36 44V42.002" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36 48C42.6274 48 48 42.6274 48 36C48 29.3726 42.6274 24 36 24C29.3726 24 24 29.3726 24 36C24 42.6274 29.3726 48 36 48Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M36 30.002V28" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5.96802 5.44601C8.1583 7.71768 11.1784 9.00067 14.334 9.00001C15.9157 9.00038 17.4808 8.6785 18.934 8.05401" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 16C15.866 16 19 12.866 19 9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9C5 12.866 8.13401 16 12 16Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M21.026 24.69C19.0141 20.4716 14.3487 18.2094 9.79066 19.2419C5.23258 20.2745 1.99757 24.3264 2 29V36H7L8 48H16L17 36H19.964" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M39 11L31 11" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M38 7L42 11L38 15" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{$t('outgoing_payment.self')}}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'accounting.transfer_outgoing'}" class="process__step">
                                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M30.032 19.948V11.948H8.032V37.946L18.032 37.948" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path
                                                    d="M36.032 17.948V7.948C36.032 6.84343 35.1366 5.948 34.032 5.948H25.136C24.0759 3.5243 21.6814 1.95814 19.036 1.95814C16.3906 1.95814 13.9961 3.5243 12.936 5.948H4.032C2.92743 5.948 2.032 6.84343 2.032 7.948V41.948C2.032 43.0526 2.92743 43.948 4.032 43.948H20.032"
                                                    stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14.032 17.948H24.032" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14.032 23.948H24.032" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14.032 29.948H20.032" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M36.032 47.948C42.6594 47.948 48.032 42.5754 48.032 35.948C48.032 29.3206 42.6594 23.948 36.032 23.948C29.4046 23.948 24.032 29.3206 24.032 35.948C24.032 42.5754 29.4046 47.948 36.032 47.948Z"
                                                      stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M27.546 44.432L44.518 27.462" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{$t('external_reconciliations.self')}}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <RouterLink :to="{name: 'chart_of_account'}" class="process__step">
                                            <svg width="45" height="52" viewBox="0 0 45 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.54545 36.9091H4.18182C2.97683 36.9091 2 35.9323 2 34.7273V4.18182C2 2.97683 2.97683 2 4.18182 2H28.1818C29.3868 2 30.3636 2.97683 30.3636 4.18182V8.54545" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M15.0909 43.4545H10.7272C9.52224 43.4545 8.54541 42.4777 8.54541 41.2727V10.7273C8.54541 9.52229 9.52224 8.54546 10.7272 8.54546H34.7272C35.9322 8.54546 36.909 9.52229 36.909 10.7273V15.0909" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0909 16.0909C15.0909 15.5386 15.5387 15.0909 16.0909 15.0909H42.4546C43.0069 15.0909 43.4546 15.5386 43.4546 16.0909V49C43.4546 49.5523 43.0069 50 42.4546 50H16.0909C15.5387 50 15.0909 49.5523 15.0909 49V16.0909Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M19.4546 23.8182L20.2531 25.4153C20.4047 25.7197 20.6936 25.9324 21.0292 25.9869C21.3648 26.0414 21.7061 25.931 21.9462 25.6902L26 21.6364" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M30.3636 23.8182H39.0909" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M19.4546 34.7273L20.2531 36.3244C20.4047 36.6287 20.6936 36.8415 21.0292 36.896C21.3648 36.9505 21.7061 36.8401 21.9462 36.5993L26 32.5455" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M30.3636 34.7273H39.0909" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M30.3636 41.2727H34.7273" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('chart_of_accounts.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'sale.report'}" class="process__step">
                                            <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.036 36.124C36.6571 35.4 36.999 34.4779 37 33.524V12H11V42H29.16C30.3285 41.9995 31.4384 41.488 32.198 40.6L36.036 36.124Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M41 5.99999H30.104C29.0439 3.57629 26.6494 2.01013 24.004 2.01013C21.3586 2.01013 18.9641 3.57629 17.904 5.99999H7C5.89543 5.99999 5 6.89542 5 7.99999V46C5 47.1046 5.89543 48 7 48H41C42.1046 48 43 47.1046 43 46V7.99999C43 6.89542 42.1046 5.99999 41 5.99999Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 18H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 26H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26 34H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('definition_of_accounts.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'sale.report'}" class="process__step">
                                            <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.036 36.124C36.6571 35.4 36.999 34.4779 37 33.524V12H11V42H29.16C30.3285 41.9995 31.4384 41.488 32.198 40.6L36.036 36.124Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M41 5.99999H30.104C29.0439 3.57629 26.6494 2.01013 24.004 2.01013C21.3586 2.01013 18.9641 3.57629 17.904 5.99999H7C5.89543 5.99999 5 6.89542 5 7.99999V46C5 47.1046 5.89543 48 7 48H41C42.1046 48 43 47.1046 43 46V7.99999C43 6.89542 42.1046 5.99999 41 5.99999Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 18H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 26H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26 34H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                            <span>{{ $t('exchange_rate_differences.self') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td></td>
                                    <td>
                                        <RouterLink :to="{name: 'sale.report'}" class="process__step">
                                            <svg width="45" height="52" viewBox="0 0 45 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.54545 36.9091H4.18182C2.97683 36.9091 2 35.9323 2 34.7273V4.18182C2 2.97683 2.97683 2 4.18182 2H28.1818C29.3868 2 30.3636 2.97683 30.3636 4.18182V8.54545" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M15.0909 43.4545H10.7272C9.52224 43.4545 8.54541 42.4777 8.54541 41.2727V10.7273C8.54541 9.52229 9.52224 8.54546 10.7272 8.54546H34.7272C35.9322 8.54546 36.909 9.52229 36.909 10.7273V15.0909" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0908 16.0909C15.0908 15.5386 15.5385 15.0909 16.0908 15.0909H42.4545C43.0067 15.0909 43.4545 15.5386 43.4545 16.0909V49C43.4545 49.5523 43.0067 50 42.4545 50H16.0908C15.5385 50 15.0908 49.5523 15.0908 49V16.0909Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M20 23H39" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M20 29H39" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M33 36H39" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M33 41H39" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M23.5071 42.7681V44.5217" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M23.5071 34V35.7536" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M20 42.7664H25.2609C26.2294 42.7664 27.0145 41.9812 27.0145 41.0127C27.0145 40.0442 26.2294 39.2591 25.2609 39.2591H21.7536C20.7851 39.2591 20 38.474 20 37.5055C20 36.537 20.7851 35.7519 21.7536 35.7519H27.0145" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{ $t('accounting_period.menu') }}</span>
                                        </RouterLink>
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        <RouterLink :to="{name: 'sale.report'}" class="process__step">
                                            <svg width="48" height="50" viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.036 36.124C36.6571 35.4 36.999 34.4779 37 33.524V12H11V42H29.16C30.3285 41.9995 31.4384 41.488 32.198 40.6L36.036 36.124Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M41 5.99999H30.104C29.0439 3.57629 26.6494 2.01013 24.004 2.01013C21.3586 2.01013 18.9641 3.57629 17.904 5.99999H7C5.89543 5.99999 5 6.89542 5 7.99999V46C5 47.1046 5.89543 48 7 48H41C42.1046 48 43 47.1046 43 46V7.99999C43 6.89542 42.1046 5.99999 41 5.99999Z" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 18H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M31 26H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M26 34H17" stroke="#898989" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <span>{{ $t('accounting.templates_of_report') }}</span>
                                        </RouterLink>
                                    </td>
                                </tr>
                            </table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import WarehouseHeader from "@/components/Warehouse/WarehouseHeader.vue";
import Side from "@/components/Warehouse/Side.vue";

export default {
    name: "Sale",

    components: {
        Side,
        PageLayout
    },
    mixins: [],

    data() {
        return {}
    },

    computed: {},

    mounted() {
        this.init();
    },

    methods: {
        init() {

        }
    }
}
</script>
